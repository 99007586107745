.recover-title-container {
  width: 100%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.recover-title-container > * {
  padding-bottom: 10px;
}
