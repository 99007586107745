.employee-card-wrapper {
  width: 100%;
  max-width: 550px;
  height: 100%;
  padding: 10px 5px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.employee-left-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.employee-left-container > *:not(:first-child) {
  margin-top: 5px;
}

.employee-right-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.new-employee-flex-row {
 width: 100%;
 padding-right: 25px;
 padding-top: 15px;
 display: flex;
 flex-direction: row;
 flex-wrap: nowrap;
 justify-content: center;
 align-items: center;
}

.employees-label-form {
  margin-left: 2.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  font-size: 1.1rem;
}
