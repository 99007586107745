.marketing-card-wrapper {
  width: 100%;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.marketing-title-button-container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.marketing-yelp-button {
  height: 25px;
  padding: 3px 10px;
  font-weight: 800;
  color: white;
  background-color: red;
  border-radius: 2px;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.6);
}

.marketing-button-wrapper {
  width: 100%;
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.marketing-button-wrapper > * {
  margin-left: 15px;
}

.marketing-card-confetti {
  width: 100%;
  height: 150px;
}

.marketing-paging-wrapper {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.marketing-page-arrow {
  width: 35px;
  height: 35px;
  padding: 3px 5px;
  fill: var(--primary);
  cursor: pointer;
}

.marketing-page-arrow-right {
  transform: rotate(180deg);
}

.marketing-page-text {
  font-size: 1.3rem;
}

.marketing-metaData-conatiner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.marketing-callback-wrapper {
  width: 100%;
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.marketing-status-info-wrapper {
  padding-left: 10px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-weight: 800;
  color: var(--primary);
  border-bottom: 1px solid var(--text-dark);
}

.marketing-status-info-wrapper > p {
  margin-right: 5px;
}

.marketing-callback-info-wrapper {
  padding-left: 10px;
  margin-top: 10px;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: row;
  align-items: center;
  font-weight: 800;
  color: var(--secondary);
  border-bottom: 1px solid var(--text-dark);
}

.marketing-callback-info-wrapper > p {
  margin-right: 5px;
}

.marketing-callback-clear {
  width: 20px;
  height: 20px;
  padding: 1px;
  position: absolute;
  top: -2px;
  left: -20px;
  border-radius: 2px;
  background-color: var(--text-dark);
  fill: var(--secondary);
  cursor: pointer;
}

.telemarketing-filter-wrapper {
  width: 100%;
  padding: 0px 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}