.checkbox-outer-wrapper-CreateListing {
  width: 100%;
  max-width: 500px;
  margin-left: 5%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.checkbox-inner-wrapper-CreateListing {
  min-width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkbox-days-inner-wrapper-CreateListing {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.time-selector-wrapper-CreateListing {
  width: 100%;
  max-width: 500px;
  margin: 25px 0px 0px 5% !important;
  display: flex;
  padding-bottom: 10px;
  justify-content: space-around;
  align-items: center;
}

.submit-outer-wrapper-CreateListing {
  width: calc(100% - 20px);
  max-width: 500px;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-SignUp-description {
  width: 90%;
  margin: 10px 0px 0px 5% !important;
  padding-bottom: 15px;
  font-size: 1rem !important;
  font-weight: 700;
  color: var(--text-dark) !important;
}

.submit-text-CreateListing {
  width: calc(100% - 10%);
}

.submit-inner-wrapper-CreateListing {
  width: 100%;
  margin: 10px 0 30px -5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.consent-wrapper-CreateListing {
  display: flex;
  justify-content: center;
  align-items: center;
}

.consent-text-CreateListing {
  margin-left: 5px;
  font-size: 0.9rem;
}

.consent-terms-container {
  display: flex;
}

.submit-button-container {
  width: 90%;
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.consent-terms-link {
  color: var(--text-dark);
  text-decoration: underline;
  cursor: pointer;
}

.consent-terms-arrow {
  width: 15px;
  margin-left: 5px;
  fill: var(--text-medium);
}

.image-preview-create-listing {
  width: 100px;
  min-height: 75px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  image-orientation: 0deg;
}

.checkbox-label {
  margin-bottom: 4px;
}

@media only screen and (max-width: 600px) {
  .checkbox-outer-wrapper-CreateListing {
    max-width: calc(100vw - 10%);
  }

  .time-selector-wrapper-CreateListing {
    max-width: calc(100vw - 10%);
    justify-content: space-around;
  }
}
