.corperate-section-title-conatiner {
  width: 100%;
  display: flex;
  justify-content: center;
}

.corperate-section-title {
  font-size: 1.1rem;
  font-weight: 500;
  text-decoration: underline;
  color: var(--text-dark);
}