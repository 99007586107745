.posted-ad-active {
  border: 1px solid var(--primary);
}

.posted-ad-inactive {
  border: none;
}

.posted-ad-overlay-gradient {
  background: rgba(0, 0, 0, 0.45);
}

.posted-ad-flex-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.posted-ad-views-container {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  display: flex;
  fill: var(--stay-white);
}

.posted-ad-views-text {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--stay-white);
}

.posted-ad-unique-mark {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 2px;
  left: 5px;
  border-radius: 100%;
  z-index: 1;
}