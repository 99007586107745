.recommended-wrapper {
  width: 100%;
  max-width: calc(100vw - 40px);
  margin: 12px 0 0 10px;
  padding: 7px;
  flex: auto;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow: scroll;
}

.recommended-card-container {
  width: 200px;
  min-width: 200px;
  height: 180px;
  margin-right: 15px;
  position: relative;
  display: flex;
  border-radius: 4px;
  box-shadow: 2px 2px 5px rgba(40, 40, 40, 0.6);
  overflow: hidden;
}

.recommended-card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  overflow: hidden;
}

.recommended-card-skyline {
  width: 100%;
  height: 60%;
  fill: var(--backgroundColor);
}

.recommended-card-title {
  position: absolute;
  bottom: 43px;
  left: 10px;
  color: var(--stay-white);
  text-shadow: 1px 1px 2px black;
  z-index: 5;
}

.recommended-card-name {
  padding-top: 4px; 
  position: absolute;
  bottom: 15px;
  left: 10px;
  font-weight: 500;
  border-top: 1px solid var(--stay-white);
  color: var(--stay-white);
  text-shadow: 1px 1px 2px black;
  z-index: 5;
}

.recommended-see-more {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(-15deg, var(--primary), var(--primary-dark), var(--primary-light));
}

.recommended-see-more-text {
  font-size: 1.6rem;
  font-weight: 700;
  color: var(--stay-white);
  text-shadow: 1px 1px 2px black;
}

.recommended-skeleton-image {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.recommended-skeleton-animation {
  background-color: rgb(236, 236, 236);
  background-color: var(--backgroundColor);
  background-image: linear-gradient(
    90deg,
    rgb(247, 247, 247) 22%,
    rgba(210, 210, 210, 0.6) 30%,
    rgb(247, 247, 247) 38%
  );
  background-image: linear-gradient(
    90deg,
    var(--backgroundColor) 22%,
    var(--skeleton-opacity) 30%,
    var(--backgroundColor) 38%
  );
  background-size: 200%;
  animation: skeletonAnimate 1.4s infinite linear;
  -webkit-animation-duration: 1.4s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: skeletonAnimate;
  -webkit-animation-timing-function: linear;
}

@-webkit-keyframes skeletonAnimate {
  from {
    background-position: 100%;
  }
  to {
    background-position: -100%;
  }
}

@keyframes skeletonAnimate {
  from {
    background-position: 100%;
  }
  to {
    background-position: -100%;
  }
}