#results-wrapper {
  height: 100vh;
}

#skelleton-wrapper {
  height: 100vh;
}

#results-inner-wrapper {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-content: flex-start;
  flex-wrap: wrap;
  overflow: auto;
}

.results-scroll-wrapper {
  width: 100%;
  position: absolute;
  top: 60px;
  display: flex;
  justify-content: center;
  z-index: 2;
}

.results-wrapper-mobile {
  min-width: 100%;
  padding-bottom: 8px;
  overflow-y: scroll;
}

.results-wrapper-web {
  width: calc(100% - 70px);
  margin-left: 70px;
  padding: 5px 10px 10px 10px;
  box-shadow: inset -2px 2px 6px rgba(10, 10, 10, 0.6);
  overflow-y: scroll;
}

.no-results-wrapper {
  width: 100%;
  height: calc(100vh - 190px);
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.no-results-option-text {
  color: var(--primary);
  cursor: pointer;
}

.no-results-option-text:hover {
  border-bottom: 1px solid var(--primary);
}

.longFadeIn {
  animation-duration: 500ms;
  animation-fill-mode: both;
  animation-name: longFadeIn;
}

@keyframes longFadeIn {
  from {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.results-covid-modal-link {
  margin-top: 10px;
  color: var(--primary);
}

.results-covid-modal-legal {
  margin: 10px 0px 15px 0px;
  font-size: 0.8rem;
}

.modal-covid-icon {
  width: auto;
  height: 30px;
  margin: -5px 0px 10px 0px;
}