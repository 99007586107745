.text-input-container {
  width: 90%;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: baseline;
}

.text-input-svg-container {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 12px;
  left: -10px;
  fill: var(--text-medium);
}

.text-input-container input {
  width: calc(100% - 80px);
  padding: 1rem 0;
  margin-left: 3rem;
  position: relative;
  text-align: left;
  border: none;
  outline: none;
  font-size: 1.5rem;
  color: var(--text-dark);
  background-color: rgba(247, 247, 247, 0.95);
  background-color: var(--background-opacity);
}

.text-input-container .text-input-bar {
  width: 85%;
  height: 1px;
  margin-left: 1.8rem;
  position: absolute;
  left: 0;
  bottom: 0;
  border-top: 1px solid var(--text-medium);
}

.text-input-container .text-input-bar::before {
  content: "";
  position: absolute;
  top: -2px;
  left: 0px;
  background-color: var(--primary);
  width: 0;
  height: 2px;
  flex-shrink: 0;
  transition: width 400ms;
}

.text-input-container input:focus + .text-input-bar::before {
  width: 100%;
}

.input-privacy-container {
  width: 20px;
  margin-left: 10px;
  fill: var(--text-medium);
}

.input-privacy-container-show {
  fill: var(--primary);
}
