.profile-images-wrapper-position {
  position: absolute;
  bottom: 0;
  left: 20px;
}

.profile-image-wrapper-position {
  position: absolute;
  bottom: 10px;
  left: 20px;
}

.profile-images-wrapper {
  float: left;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: nowrap;
}

.profile-image-wrapper {
  margin-right: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-image-container {
  height: 45px;
  width: 45px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #323435;
  border: 1px solid var(--text-dark);
  border-radius: 25%;
  box-shadow: 2px 2px 4px rgba(40, 40, 40, 0.4);
  background-color: var(--opacity-light);
  cursor: pointer;
  overflow: hidden;
  transition: transform 100ms ease-in-out;
}

.profile-image-container-main {
  height: 55px;
  width: 55px;
  border: 2px solid var(--primary);
}

.profile-image-container-dragging {
  transform: scale(1.1);
}

.profile-images {
  width: 300px;
}

.profile-image {
  image-orientation: from-image;
  float: left;
}

.profile-image-delete {
  width: 20px;
  height: 20px;
  padding: 4px;
  position: absolute;
  top: -8px;
  left: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25%;
  background-color: var(--secondary);
  box-shadow: 2px 2px 4px rgba(40, 40, 40, 0.4);
  overflow: hidden;
  z-index: 2;
}

.profile-image-delete > svg {
  fill: var(--stay-white);
}

.profile-svg-container {
  width: 55%;
  height: 55%;
  fill: hsl(197, 99%, 40%);
  fill: var(--primary);
}
