.menu-page-click-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.65);
  background-color: var(--opacity-dark);
  z-index: 88;
  visibility: hidden;
}

.sidebar-menu-wrapper {
  width: 250px;
  max-width: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  overflow: hidden;
  border-radius: 10px 0 0 10px;
  background-color: rgb(247, 246, 246);
  background-color: var(--backgroundColor);
  box-shadow: 0 5px 10px #333;
  z-index: 89;
}

.sidebar-menu-item-wrapper {
  height: calc(100vh - 80px);
  overflow-y: scroll;
}

.sidebar-title {
  width: 100%;
  padding: 0 15px;
  margin: 25px 0 10px 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.sidebar-title > svg {
  margin-left: 10px;
  fill: var(--text-dark);
}

.sideMenu-menu-item-container {
  width: 90%;
  height: 50px;
  margin-left: 5%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  color: var(--text-dark);
  border-bottom: 1px solid #999b9c;
  border-bottom: 1px solid var(--text-medium);
  cursor: pointer;
}

.sideMenu-menu-item {
  min-height: 50px;
}

.sideMenu-menu-item-dropDown-subsection {
  width: 90%;
  margin-left: 10%;
  max-height: 0;
  color: var(--text-dark);
  overflow: hidden;
  transition: max-height 250ms ease-in;
}

.sideMenu-menu-item-dropDown-subsection-open {
  max-height: 100px;
}

.sideMenu-menu-item-container:hover {
  background-color: rgb(236, 236, 236);
  background-color: var(--backgroundColor-secondary);
}

.sideMenu-menu-item-container > p {
  white-space: nowrap;
}

.sideMenu-menu-icon-container {
  width: 30px;
  height: 25px;
  margin: 0 10px;
  fill: hsl(197, 99%, 40%);
  fill: var(--text-dark);
}

.sideMenu-menu-carret-container {
  width: 15px;
  height: 15px;
  position: absolute;
  top: calc(50% - 8px);
  right: 20px;
  fill: var(--text-dark);
  transform: rotate(-90deg);
}

.sideMenu-menu-carret-container-open {
  transform: rotate(90deg);
}

.fade-enter {
  opacity: 0;
  visibility: visible;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-out;
}
.fade-enter-done {
  opacity: 1;
  visibility: visible;
}

.fade-exit {
  opacity: 1;
  visibility: visible;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 250ms ease-in;
}
.fade-exit-done {
  opacity: 0;
}

.slide-enter {
  max-width: 0;
}
.slide-enter.slide-enter-active {
  max-width: 250px;
  transition: max-width 200ms ease-out;
}
.slide-enter-done {
  max-width: 250px;
}

.slide-exit {
  max-width: 250px;
}
.slide-exit.slide-exit-active {
  max-width: 0;
  transition: max-width 200ms ease-in;
}
