.flex-center {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-space-around {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.text-center {
  margin: 5px 0px 13px 0px;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
}

.analytics-spacing {
  margin-top: 10px;
}

.analytics-title {
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--text-dark);
}

.analytics-header-container {
  width: calc(100% - 20px);
  margin: 0 0 45px 10px;
  padding: 15px 10px 50px 10px;
  position: relative;
  border-radius: 6px;
  background-color: rgba(247, 247, 247, 0.95);
  background-color: var(--background-opacity);
  box-shadow: 0 0 4px rgba(10, 10, 10, 0.8);
}

.analytics-selector-container {
  width: 90%;
  height: 60px;
  position: absolute;
  bottom: -20px;
  left: 5%;
  border-radius: 6px;
  background-color: var(--text-medium);
  box-shadow: 0 4px 8px rgba(10, 10, 10, 0.7);
  overflow: hidden;
  z-index: 99;
}

.analytics-reset-container {
  width: 25px;
  height: 25px;
  padding: 4px 4px 5px 4px;
  position: absolute;
  top: 9px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background-color: var(--secondary);
  fill: var(--stay-white);
  box-shadow: 2px 2px 4px rgba(40, 40, 40, 0.4);
  cursor: pointer;
}

.analytics-no-text {
  width: 100%;
  margin-top: 100px;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
}
