.input-container-Input {
  min-width: 150px;
  height: 22px;
  padding: 0 2px;
  margin-left: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--text-medium);
  overflow: hidden;
  cursor: pointer;
}

.input-svg-container-Input {
  width: 15px;
  min-width: 15px;
  height: 15px;
  margin: 0 5px;
}

.input-container-Input > select {
  font-weight: 400;
  border: none;
  color: var(--text-dark);
  background-color: transparent;
  cursor: pointer;
}

.input-svg-container-Input > svg {
  fill: var(--text-dark);
}
