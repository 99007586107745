.payment-wrapper {
  width: calc(100% + 30px);
  max-height: 80vh;
  padding: 0px 15px 20px 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
}

.payment-status-text {
  margin-top: 5px;
  text-align: center;
}

.payment-status-active {
  font-size: 1.1rem;
  font-weight: 700;
  color: var(--primary);
}

.payment-status-expired {
  font-size: 1.1rem;
  font-weight: 700;
  color: var(--secondary);
}

.auto-pay-text {
  text-align: center;
  font-weight: 500;
}

.payment-form-container {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.payment-form-group {
  width: 100%;
  margin-top: 5px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.payment-selector-container {
  width: calc(100% - 10px);
  height: 60px;
  margin-left: 5px;
  flex-shrink: 0;
  border-radius: 6px;
  background-color: var(--text-medium);
  box-shadow: 1px 1px 8px rgba(10, 10, 10, 0.6);
  overflow: hidden;
  z-index: 99;
}

.comparison-chart-container {
  width: 100%;
}

.payment-promo-container {
  width: 100%;
  height: 70px;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
}

.payment-row {
  margin-top: 15px;
}

.payment-row-right {
  min-width: calc(100% - 10px);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.payment-type-text {
  margin: 0px 20px;
  font-size: 1.2rem;
  color: var(--text-dark);
}

.payment-type-text-active {
  font-size: 1.3rem;
  font-weight: 600;
  border-bottom: 2px solid var(--primary);
}

.payment-label-adjustment {
  margin: 4px 8px 0 0;
}

.payment-checkbox-label {
  margin-bottom: 5px;
}

.payment-form-cardNumber {
  width: 100%;
  height: 35px;
  padding: 5px;
  margin-top: 5px;
  border: 2px inset var(--text-medium);
  color: var(--test-dark);
  background: none;
}

.payment-form-promoCode {
  width: calc(100% - 110px);
  height: 35px;
  padding: 5px;
  border: 2px inset var(--text-medium);
  color: var(--test-dark);
  background: var(--backgroundColor);
}

.payment-upgrade-text {
  font-size: 1.1rem;;
  font-weight: 600;
  text-align: center;
  color: var(--text-dark);
}

.FormGroup {
  width: 100%;
  height: 35px;
	margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
	will-change: opacity, transform;
	border-style: none;
	/* background: linear-gradient(var(--primary), var(--primary-dark), var(--primary));
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #829fff;
	border-radius: 4px; */
  border: 2px inset var(--text-medium);
  color: var(--test-dark);
  background: none;
}

.FormRow {
  width: 100%;
  height: 35px;
  padding: 0px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin-left: 15px;
	/* border-top: 1px solid #819efc; */
}

.StripeElement--webkit-autofill {
	background: transparent !important;
}

.StripeElement {
	width: 100%;
	padding: 11px 15px 11px 0;
}