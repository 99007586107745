.profile-drop-down-category-container {
  width: 23px;
  height: 23px;
  margin: 0 10px;
  fill: #323435;
  fill: var(--text-dark);
}

.profile-drop-down-carret-container {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 15px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: var(--primary);
  transform: rotate(90deg);
}

.profile-drop-down-carret-container-open {
  transform: rotate(-90deg);
}

.profile-drop-down-sub-container {
  width: calc(100% - 15px);
  margin-left: 15px;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 250ms ease-in-out;
}

.profile-drop-down-sub-container-open {
  max-height: 700px;
}
