.yelp-specs-container {
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.yelp-logo {
  width: 100px;
  position: absolute;
  top: -7px;
  left: 15px;
  transform: rotate(-3deg);
  z-index: 1;
}

.yelp-review-wrapper {
  margin-top: -12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.yelp-review-conatiner {
  width: 83px;
  height: 20px;
  margin-bottom: 2px;
  position: relative;
}

.yelp-review-fill-clip {
  height: 18px;
  position: absolute;
  top: 1px;
  left: 1px;
  background-color: rgb(191, 36, 26);
  clip-path: url(#rating-clip);
}

.yelp-review-count-text {
  color: var(--text-dark);
}

.yelp-review-count {
  color: rgb(191, 36, 26);
}

.yelp-review-conatiner > svg {
  position: relative;
  fill: rgba(247, 247, 247);
  fill: var(--backgroundColor);
}

.yelp-image-preview {
  width: 150px;
  min-width: 150px;
  height: 150px;
  margin: 5px 10px 5px 0;
  display: flex;
  align-items: flex-end;
  border-radius: 5px;
  background-color: var(--backgroundColor-secondary);
  fill: var(--backgroundColor);
  box-shadow: 0 0 2px rgba(10, 10, 10, 0.8);
  overflow: hidden;
}
