.splash-page-wrapper {
  width: 100vw;
  height: 100vh;
  padding-bottom: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--backgroundColor);
  overflow: hidden;
  overflow-y: scroll;
  z-index: 9999;
}

.splash-icon-container {
  width: 20%;
  max-width: 100px;
}

.splash-icon {
  width: 100%;
}

.splash-Name-container {
  width: 70%;
  max-width: 420px;
  margin-top: 20px;
  fill: var(--text-dark);
}

.splash-error-text {
  color: var(--secondary);
}