.legal-wrapper {
  width: 90%;
  height: 100vh;
  margin-left: 5%;
  padding: 50px 0 30px 0;
  overflow-y: scroll;
}

.legal-wrapper > h2 {
  margin: 20px 0 2px 0;
  text-decoration: underline;
}

.legal-wrapper > p > a {
  color: var(--text-dark);
  text-decoration: underline;
  cursor: pointer;
}

.legal-wrapper > ul {
  margin-left: 5%;
}
