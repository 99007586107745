.social-icons-conatiner {
  width: 70%;
  margin-left: 15%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.social-icon {
  width: 75px;
  border-radius: 17px;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.6);
  overflow: hidden;
}