.profile-top-selection-wrapper {
  padding-bottom: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.profile-skyline-container {
  width: 100%;
  position: absolute;
  background-color: var(--backgroundColor);
  fill: var(--backgroundColor-secondary);
}

.profile-gradient-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, transparent 35%, rgb(247, 246, 246));
  background: linear-gradient(180deg, transparent 35%, var(--backgroundColor));
  z-index: 0;
}

.profile-top-selection-container {
  width: 100%;
  height: 250px;
  padding: 2.5% 5%;
  position: relative;
  z-index: 1;
}

.profile-button-container {
  height: calc(70% + 10px);
  position: absolute;
  bottom: 2px;
  right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.profile-button-container > button:first-child {
  margin-bottom: 10px;
}

.profile-text-container {
  width: calc(100% - 10px);
  height: 36px;
  margin: 3px 5px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 1.3rem;
  background-color: var(--background-on-opacity);
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.profile-title-container {
  width: calc(100% - 55px);
  height: 25px;
  margin-left: 25px;
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  font-size: 1.1rem;
  color: #999b9c;
  color: var(--text-dark);
}

.profile-text-container-left {
  justify-content: flex-start;
}

.profile-text-container-split {
  justify-content: space-between;
}

.profile-text-container-center {
  justify-content: center;
}

.profile-text-container > p:first-child {
  margin-left: 5px;
  font-size: 1.3rem;
}

.profile-text-container-split > p:last-child {
  margin-right: 5px;
}

.profile-ad-toggle-container {
  height: 30px;
  margin-top: 5px;
  margin-right: 15px;
}

.profile-text-no-value {
  color: var(--text-dark);
}

.profile-pagination-total {
  margin-left: 5px;
  font-size: 1.1rem;
  color: var(--primary);
}