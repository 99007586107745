.pagination-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pagination-title-selected {
  padding-bottom: 2px;
  font-weight: 700;
  border-bottom: 1px solid var(--primary);
}