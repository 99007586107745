.image-preview {
  box-shadow: 0 0 3px rgba(0,0,0,0.8);
}

.image-preview-close-container {
  width: 25px;
  height: 25px;
  padding: 3px;
  position: absolute;
  top: -6px;
  right: -12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  fill: var(--stay-white);
  background-color: var( --secondary);
  box-shadow: -1px 1px 3px rgba(0,0,0,0.6);
  cursor: pointer;
}
