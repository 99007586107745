.sideNav-page-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 2;
}

.sideNav-wrapper {
  width: 100%;
  max-width: 70px;
  height: calc(100vh - 114px);
  padding: 0 11px;
  position: fixed;
  left: 0;
  top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--backgroundColor);
  box-shadow: 2px 0 6px rgba(10, 10, 10, 0.6);
  transition: all 250ms ease-in-out;
  overflow: hidden;
  z-index: 2;
}

.sideNav-wrapper > * {
  margin-top: 20px;
}

.sideNav-wrapper-open {
  max-width: 260px;
}

.sideNav-svg > svg {
  fill: var(--primary);
}

.sideNav-svg-dark > svg {
  fill: var(--text-dark);
}

.sideNav-svg-secondary {
  fill: var(--secondary);
}

.sideNav-border {
  border-radius: 5px;
  border: 1px solid var(--text-dark);
}

.sideNav-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sideNav-svg-arrow {
  width: 97%;
  height: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.sideNav-svg-arrow > svg {
  width: 15px;
  fill: var(--text-dark);
  transition: all 250ms ease-in-out;
}

.sideNav-svg-arrow-open > svg {
  transform: rotate(180deg);
}

.sideNav-icon-container {
  width: 25px;
  height: 25px;
  margin-left: 12px;
}

.sideNav-icon-path-container {
  width: 45px;
  min-width: 45px;
  height: 35px;
  min-height: 35px;
  margin-left: 2px;
  padding: 6px 11px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sideNav-icon-path-container-secondary {
  border-radius: 5px;
  border: 1px solid var(--secondary);
}

.side-nav-path-isActive > svg {
  fill: var(--stay-white);
}

.sideNav-subcat-select {
  min-width: 160px;
  max-width: 160px;
  height: 35px;
  padding-left: 5px;
  margin-left: 10px;
  color: var(--text-dark);
  background-color: transparent;
  opacity: 0;
  animation: fadeIn 250ms linear 150ms forwards;
}

.sideNav-calander {
  margin-left: 10px;
  padding-left: 10px;
}

.sideNav-select {
  width: 170px;
  height: 30px;
  margin-left: 10px;
  border: none;
  color: var(--text-dark);
  background-color: transparent;
  opacity: 0;
  animation: fadeIn 250ms linear forwards;
}

.sideNav-description-container {
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  opacity: 0;
  animation: slideIn 250ms linear forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-25px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.sideNav-description-icon {
  width: 10px;
  height: 15px;
}

.sideNav-description {
  margin-left: 13px;
  color: var(--text-dark);
}

.sideNav-icon-input-container {
  width: 100%;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
  padding: 3px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}

.sideNav-input-autoComplete-wrapper {
  width: calc(100% - 2px);
  margin-left: 2px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.sideNav-autoComplete-container {
  width: 100%;
  padding: 0 5px;
}

.sideNav-input-icon {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  margin-left: 9px;
}

.sideNav-input {
  width: 100%;
  padding: 0 5px 0 10px;
  border: none;
  color: var(--text-dark);
  background-color: transparent;
  opacity: 0;
  animation: fadeIn 250ms linear forwards;
}

@keyframes fadeIn {
  from {
    width: 0%;
    opacity: 0;
  }
  to {
    width: 100%;
    opacity: 1;
  }
}

.sideNav-container:hover > .sideNav-icon-path-container {
  transform: scale(1.08);
}

.sideNav-container-secondary:hover > .sideNav-icon-path-container {
  transform: scale(1.08);
}

.sideNav-container-input:hover {
  transform: scale(1.08);
}

.sideNav-isActive {
  border: 1px solid var(--primary);
}

.sideNav-isActive > svg {
  fill: var(--primary);
}

.sideNav-isActive > div > svg {
  fill: var(--primary);
}
