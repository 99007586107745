.corporate-title-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.corporate-total-text {
  font-size: 1.2rem;
}

.corporate-total-highlight-text {
  font-weight: 600;
  color: var(--primary);
}

.corporate-location-container {
  width: calc(100% - 20px);
  height: 80px;
  margin: 12px 0px 0px 10px;
  position: relative;
  border-radius: 5px;
  background-color: rgb(247, 246, 246);
  background-color: var(--backgroundColor);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 1px 1px 5px rgba(10, 10, 10, 0.9);
  overflow: hidden;
  cursor: pointer;
}

.corporate-overlay {
  width: 100%;
  height: 100%;
  padding: 10px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0,0,0,0.35);
}

.corporate-metadata-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.corporate-state-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.corporate-state-container > p {
  margin-right: 5px;
}

.corporate-metadata-text {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--stay-white);
  text-shadow: 1px 1px 3px rgba(0,0,0,1);
}

.corporate-options-container {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.corporate-delete-container {
  width: 26px;
  height: 26px;
  padding: 4px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  fill: hsl(6, 100%, 75%);
  fill: var(--secondary-light);
  border: 1px solid var(--secondary-light);
  border-radius: 100%;
  background-color: rgba(0,0,0,0.3);
  box-shadow: 1px 1px 3px rgba(0,0,0,0.5);
  cursor: pointer;
}
