.timeDisplay-wrapper-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeDisplay-wrapper {
  width: 100%;
  max-width: 750px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.time-left-text-container {
  min-width: 75px;
  margin: 0 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.timeDisplay-time {
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
}

.timeDisplay-time-title {
  font-size: 0.7rem;
  font-weight: 600;
}

.time-left-center-container {
  min-width: calc(100% - 150px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.time-left-container {
  min-width: 100%;
  height: 10px;
  padding: 1px;
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  border-radius: 5px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.4);
  border: 1px solid var(--text-light);
  overflow: hidden;
}

.time-left-remaing-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: -2px 0 4px rgba(0, 0, 0, 0.4);
}

.time-left-remaing-container-starting {
  background-color: transparent;
}

.time-left-remaing-container-active {
  background-color: var(--primary);
}

.time-left-remaing-container-ending {
  background-color: var(--secondary);
}

.listing-background-ended {
  background-color: #323435;
}

.time-left-text {
  font-size: 0.9rem;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  color: var(--text-dark);
}