.radar {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid var(--text-dark);
  position: absolute;
  top: 20px;
  left: 20px;
}

.radar .pointer {
  position: absolute;
  left: 10.5820106%;
  right: 10.5820106%;
  top: 10.5820106%;
  bottom: 50%;
  will-change: transform;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  border-radius: 50% 50% 0 0 / 100% 100% 0 0;
  background-image: linear-gradient(
    135deg,
    var(--text-dark) 0%,
    rgba(0, 0, 0, 0.02) 70%,
    rgba(0, 0, 0, 0) 100%
  );
  -webkit-clip-path: polygon(100% 0, 100% 10%, 50% 100%, 0 100%, 0 0);
  clip-path: polygon(100% 0, 100% 10%, 50% 100%, 0 100%, 0 0);
  -webkit-animation: rotate360 3s infinite linear;
  animation: rotate360 3s infinite linear;
  z-index: 99;
}

.radar .pointer:after {
  content: "";
  position: absolute;
  width: 50%;
  bottom: -1px;
  border-top: 2px solid var(--text-dark);
  box-shadow: 0 0 3px var(--text-dark);
  border-radius: 9px;
}

@-webkit-keyframes rotate360 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes rotate360 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes shadow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes shadow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
