.confirm-email-page-wrapper {
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.confirm-email-redirect-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.confirm-email-email-text {
  margin-bottom: 10px;
  font-size: 1.6rem;
  color: hsl(197, 99%, 40%);
  color: var(--primary);
}

.confirm-email-text-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: hsl(6, 100%, 60%);
  color: var(--secondary);
}

.confirm-email-finger-container {
  width: 35px;
  margin: 0 10px;
  display: inline-block;
  stroke-width: 1.5px;
  stroke: hsl(197, 99%, 40%);
  stroke: var(--primary);
}

.confirm-email-skyline-container {
  width: 100%;
}