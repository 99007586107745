.checkbox-Checkbox {
  width: 25px;
  min-width: 25px;
  height: 25px;
  border-style: inset;
  border-width: 2px;
  border-color: var(--text-light);
  cursor: pointer;
}

.checkbox-disabled {
  background-image: var(--backgroundColor-secondary) !important;
}

.checkbox-Checkbox > svg {
  fill: hsl(343, 100%, 30%);
  fill: var(--primary);
}
