.saved-ad-wrapper {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}

.saved-ad-skyline-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -40%;
  left: 0;
  display: flex;
  align-items: flex-start;
  fill: var(--backgroundColor-secondary);
  z-index: 0;
}

.saved-ad-container {
  width: 100%;
  height: 100px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
  z-index: 1;
}

.saved-ad-sub-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.saved-ad-sub-container-split {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.saved-ad-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--stay-white);
}

.saved-ad-name {
  padding-top: 3px;
  font-size: 1rem;
  font-weight: 300;
  color: var(--stay-white);
}
