.detail-view-outer-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
}

.detail-view-inner-wrapper {
  height: 100vh;
  width: 100vw;
  position: fixed;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
}

.listing-page-background-image-container {
  width: 100vw;
  min-width: 100vw;
  height: 100vh;
  object-fit: cover;
  object-position: 50%;
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  filter: blur(10px);
}

.listing-page-background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.listing-content-images-container {
  width: calc(100% - 20px);
  margin: 0 0 25px 10px;
  padding-bottom: 75%;
  position: relative;
  border-radius: 6px;
  background-color: rgba(247, 247, 247, 0.95);
  background-color: var(--background-opacity);
  box-shadow: 0 0 4px rgba(10, 10, 10, 0.8);
  overflow: hidden;
}

.listing-images-Wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  transition: transform 200ms ease-in-out;
}

.listing-image {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  object-fit: cover;
  object-position: 50%;
}

.listing-no-image {
  width: 100vw;
  min-height: 20px;
  position: fixed;
  top: 40px;
  left: 0;
  fill: var(--backgroundColor);
}

.listing-background-gradient-overlay {
  width: 100%;
  height: calc(100vh - 40px);
  position: fixed;
  top: 40px;
  left: 0;
  background: linear-gradient(
    0deg,
    rgba(0,0,0,0.6) 5%,
    transparent 50%
  );
}

.listing-content-wrapper {
  width: 100%;
  max-width: 850px;
  position: relative;
  z-index: 0;
}

.listing-image-window {
  width: 100%;
  height: 100%;
  position: absolute;
}

.listing-image-arrow-wrapper {
  width: 100%;
  height: 20px;
  padding: 0 15px;
  position: absolute;
  top: 45%;
}

.listing-image-enlarge-container {
  padding: 5px;
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  border-radius: 2px;
  background-color: rgba(30,30,30,0.6);
  fill: var(--stay-white);
  cursor: pointer;
}

.listing-image-enlarge-container:hover {
  background-color: rgba(30,30,30,0.8);
}

.listing-image-arrow-container {
  padding: 5px 3px;
  float: right;
  display: flex;
  border-radius: 2px;
  background-color: rgba(30,30,30,0.6);
  fill: var(--stay-white);
  cursor: pointer;
}

.listing-image-arrow-container:hover {
  background-color: rgba(30,30,30,0.8);
}

.listing-image-arrow-left {
  float: left;
  transform: rotate(180deg);
}

.listing-image-dots-container {
  width: 100%;
  height: 20px;
  position: absolute;
  bottom: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listing-image-dot {
  width: 12px;
  height: 3px;
  margin: 0 5px;
  border-radius: 2;
  background-color: var(--stay-white);
  box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 1);
  opacity: 0.5;
}

.listing-image-dot-selected {
  opacity: 1;
}

.listing-image-dot-starting, .listing-image-dot-undefined {
  background-color: var(--text-medium);
}

.listing-image-dot-active {
  background-color: var(--primary);
}

.listing-image-dot-ending {
  background-color: var(--secondary);
}

.listing-image-dot-ended {
  background-color: black;
}
