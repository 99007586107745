.fade-enter {
  opacity: 0;
  visibility: visible;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-out;
  visibility: visible;
}
.fade-enter-done {
  opacity: 1;
  visibility: visible;
}
.fade-exit {
  opacity: 1;
  visibility: visible;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 250ms ease-in;
}
.fade-exit-done {
  opacity: 0;
  visibility: hidden;
}

.top-slide-enter {
  opacity: 0;
  top: 45%;
  visibility: visible;
}
.top-slide-enter.top-slide-enter-active {
  opacity: 1;
  top: 50%;
  transition: all 200ms ease-in-out;
  visibility: visible;
}
.top-slide-enter-done {
  opacity: 1;
  top: 50%;
  visibility: visible;
}
.top-slide-exit {
  opacity: 1;
  top: 50%;
  visibility: visible;
}
.top-slide-exit.top-slide-exit-active {
  opacity: 0;
  top: 45%;
  transition: all 200ms ease-in-out;
}
.top-slide-exit-done {
  top: 45%;
  opacity: 0;
  visibility: hidden;
}

.flip-card {
  background-color: transparent;
  perspective: 1000px;
}

.flip-card:nth-child(1) {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.cardFlip-appear,
.cardFlip-enter {
  position: absolute;
  backface-visibility: hidden;
  transform: rotateY(-180deg);
}

.cardFlip-appear-active,
.cardFlip-enter.cardFlip-enter-active {
  transform: rotateY(-360deg);
  transition: transform 500ms linear;
}

.cardFlip-exit {
  position: absolute;
  backface-visibility: hidden;
  transform: rotateY(0deg);
}

.cardFlip-exit.cardFlip-exit-active {
  transform: rotateY(-180deg);
  transition: transform 500ms linear;
}

.shake {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: shake;
  animation-name: shake;
}

@-webkit-keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

@keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
