.card-center-conatiner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  position: fixed;
}

.card-stack-conatiner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.card-stack-conatiner > div {
  margin-top: 10px;
}

.result-map-card-container {
  width: 90%;
  max-width: 600px;
  height: 100%;
  min-height: 85px;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: nowrap;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(10, 10, 10, 0.8);
  overflow: hidden;
  cursor: pointer;
  z-index: 2;
}

.result-card-map-overlay-gradient {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 50%, transparent);
}

.result-map-card-container-starting {
  background: linear-gradient(var(--text-medium), var(--text-dark) 90%);
}

.result-map-card-container-active {
  background: linear-gradient(var(--primary-dark), var(--primary) 90%);
}

.result-map-card-container-ending {
  background: linear-gradient(var(--secondary-dark), var(--secondary) 90%);
}

.result-map-card-container-ended {
  background: linear-gradient(#323435, #4a4d4e 90%);
}

.result-card-right-container {
  width: 85px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 2;
}

.result-map-info-container {
  width: calc(100% - 90px);
  color: var(--stay-white);
  z-index: 2;
}

.result-map-title {
  margin-bottom: 3px;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.7rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
  overflow: hidden;
}

.result-map-business-name {
  max-width: 100%;
  padding: 5px 0 2px 0;
  font-size: 1.1rem;
  font-weight: 400;
  letter-spacing: 0.05rem;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  border-top: 1px solid var(--stay-white);
  overflow: hidden;
}

.result-map-time-container {
  margin-top: 4px;
  text-align: center;
  color: var(--stay-white);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.result-map-time-title {
  font-size: 0.7rem;
  font-weight: 700;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.result-map-time {
  margin-top: 1px;
  font-size: 1.1rem;
  font-weight: 400;
}

.result-svg-container {
  width: 50px;
  padding: 2px 12px;
  position: relative;
  cursor: n-resize;
}

.result-camera-down {
  cursor: s-resize;
}

.result-svg-container > svg {
  fill: var(--stay-white);
}

.result-camera-flash {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  left: 10px;
  background: radial-gradient(
    rgba(245, 245, 245, 0.5) 40%,
    transparent,
    transparent
  );
  opacity: 0;
  animation: flash 2000ms linear 1s infinite;
}

@keyframes flash {
  from {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  10% {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
