.time-outer-wrapper-TimeSelector {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.time-select {
  margin-top: 3px;
  padding-left: 25px;
  border: none;
  color: var(--text-dark);
  border: 1px solid var(--text-dark);
  border-radius: 4px;
  background-color: transparent;
  font-size: 1.6rem !important;
  box-shadow: 0 0 2px rgba(10,10,10,.8);
  cursor: pointer;
}