.chart-wrapper {
  width: 100%;
  height: 250px;
  position: relative;
}

.chart-area-desc-container {
  position: absolute;
  bottom: 33px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.chart-area-desc-text {
  font-size: 0.7rem;
  color: var(--text-dark);
}

.chart-area-desc-arrow {
  width: 11px;
  margin-left: 5px;
  display: flex;
  fill: var(--text-dark);
}

.recharts-default-tooltip {
  background-color: var(--backgroundColor) !important;
}