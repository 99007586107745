.searchbar-container-SearchBar {
  width: calc(100vw - 50px);
  height: 95%;
  padding: 5px 2px 5px 5px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  border-radius: 4px;
  border: 1px solid var(--text-medium);
}

.searchbar-input-container-SearchBar {
  width: 60%;
  height: 100%;
  padding-right: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.searchbar-input-SearchBar {
  width: 100%;
  margin-left: 5px;
  color: var(--text-dark);
  background: none;
  border: none;
}

.searchbar-input-clear-container {
  width: 20px;
  min-width: 20px;
  height: 20px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: var(--primary);
}

.searchbar-location-container-SearchBar {
  width: 40%;
  height: 100%;
  padding-left: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-left: 1px solid var(--text-light);
}

.searchbar-location-SearchBar {
  width: 100%;
  padding-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-dark);
  background: none;
  border: none;
}

.searchbar-svg-container-SearchBar {
  width: 15px;
  min-width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.searchbar-svg-container-SearchBar > svg {
  fill: var(--text-medium);
  cursor: pointer;
}

.searchbar-svg-location > svg {
  fill: var(--text-medium);
}

.searchbar-svg-current-location > svg {
  fill: var(--primary);
}

.searchbar-autocomplete-container {
  max-height: 0px;
  width: calc(100% + 2px);
  position: absolute;
  bottom: 2px;
  left: -1px;
  transform: translate(0, 100%);
  padding: 0;
  border: none;
  border-radius: 0 0 5px 5px;
  background-color: var(--backgroundColor-secondary);
  box-shadow: 0 3px 2px rgba(10, 10, 10, 0.2);
  overflow: hidden;
}

.searchbar-autocomplete-container-show {
  max-height: 300px;
  padding: 5px;
  border-right: 1px solid var(--text-light);
  border-left: 1px solid var(--text-light);
  border-bottom: 1px solid var(--text-light);
  transition: max-height 250ms ease-in;
}

.searchbar-autocomplete-divider {
  width: 95%;
  margin-left: 2.5%;
  border-top: 1px solid var(--text-light);
}
