.user-promoId-container {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.user-promo-code-container {
  min-width: 120px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.user-promo-code-text {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--primary);
}

.user-promoId-container > p {
  font-size: 1.3rem;
  padding-right: 5px;
}

.user-promoId-text-container {
  width: calc(100% - 10px);
  margin: 3px 5px;
  padding-left: 30px;
  position: relative;
  display: list-item;
  font-size: 1.3rem;
  background-color: var(--background-on-opacity);
  list-style-type: circle;
  overflow: hidden;
  z-index: 1;
}

.user-promoId-text-container > li {
  padding: 5px;
  font-size: 1.2rem;
}

.user-promo-disclaimer {
  width: calc(100% + 15px);
  margin-left: -20px;
  padding: 5px 0 10px 0;
  font-size: 0.9rem;
}
