.share-button-container {
  padding: 4px;
  padding-bottom: 7px;
  position: absolute;
  top: 10px;
  right: 12px;
  border: 1px solid var(--stay-white);
  border-radius: 100%;
  z-index: 3;
  cursor: pointer;
}

.share-button-container-dark {
  border: 1px solid var(--text-dark);
}

.share-button-container > svg {
  fill: var(--stay-white);
}

.share-button-container-dark > svg {
  fill: var(--text-dark);
}

.share-options-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.share-option-container {
  width: 100%;
  padding: 5px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.share-text-conatainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  color: var(--text-dark);
}

.share-arrow {
  width: 13px;
  height: 13px;
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.share-arrow > svg {
  fill: var(--text-dark);
}

.share-option-container > svg {
  width: 30px;
  height: 30px;
  fill: var(--text-dark);
}

.share-option-container > p {
  font-size: 1.2rem;
  color: var(--text-dark);
}
