.image-modal-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: var(--opacity-dark);
  visibility: hidden;
  z-index: 150;
}

.image-modal-close-container {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 15px;
  right: 15px;
}

.image-modal-close-container > svg {
  fill: var(--text-medium);
}

.image-modal-image {
  max-width: 850px;
  height: auto;
  border-radius: 5px;
  overflow: hidden;
}
