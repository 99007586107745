.error-bar-wrapper {
  height: 100%;
  width: 80%;
  max-width: 280px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 0 5px black;
  z-index: 1001;
  visibility: hidden;
}

.error-bar-svg-container {
  height: 35px;
  width: 30px;
  min-width: 30px;
  margin-left: 10px;
  fill: #f1f1f1;
  fill: var(--stay-white);
}

.error-text {
  width: 100%;
  padding: 10px 15px;
  text-align: center;
  font-size: 1.1rem;
  color: #f1f1f1;
  color: var(--stay-white);
}

.error-enter {
  height: 0px;
  visibility: visible;
}
.error-enter.error-enter-active {
  height: 50px;
  transition: height 250ms ease-out;
}
.error-enter-done {
  height: 50px;
  visibility: visible;
}

.error-exit {
  max-height: 50px;
  visibility: visible;
}
.error-exit-active {
  max-height: 0px;
  transition: max-height 250ms ease-in;
}
.error-exit-done {
  max-height: 0px;
}
