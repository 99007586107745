.bottom-drawer-wrapper {
  height: 100%;
  width: 100vw;
  max-width: 500px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px 10px 0 0 ;
  background-color: var(--backgroundColor);
  box-shadow: 0 0 5px black;
  z-index: 1001;
  visibility: hidden;
}

.bottom-drawer-title {
  width: 100%;
  padding-bottom: 20px;
  margin: 20px 5px 25px 5px;
  text-align: center;
  font-size: 1.5rem;
  border-bottom: 1px solid var(--text-medium);
}

.bottom-drawer-enter {
  height: 0px;
  visibility: visible;
}
.bottom-drawer-enter.bottom-drawer-enter-active {
  height: 300px;
  transition: height 250ms ease-out;
}
.bottom-drawer-enter-done {
  height: 300px;
  visibility: visible;
}

.bottom-drawer-exit {
  max-height: 300px;
  visibility: visible;
}
.bottom-drawer-exit-active {
  max-height: 0px;
  transition: max-height 250ms ease-in;
}
.bottom-drawer-exit-done {
  max-height: 0px;
}
