.covid-wrapper {
  width: 100%;
  max-height: 90vh;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
}

.covid-modal-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.covid-modal-icon {
  width: 25px;
  height: 25px;
  margin-left: 5px;
}

.covid-agree-container {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.covid-features-container {
  width: 100%;
  margin-left: -10px;
}

.covid-features-container > div {
  box-shadow: none !important;
}

.covid-agree-container > div{
  margin-right: 10px;
}

.covid-more-info-link {
  margin-top: 5px;
  color: var(--primary);
}

.covid-more-info-link:active {
  color: var(--primary);
}

.covid-modal-button-container {
  width: 100%;
  margin: 20px 0px 5px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
