.growth-wrapper {
  width: 100vw;
  height: calc(100vh - 40px);
  padding: 10px;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}

.growth-wrapper > * {
  margin: 10px;
}
