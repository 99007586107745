.data-entry-reset {
  width: 25px;
  height: 25px;
  padding: 4px 4px 5px 4px;
  position: absolute;
  top: 0px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background-color: var(--secondary);
  fill: var(--stay-white);
  box-shadow: 2px 2px 4px rgba(40, 40, 40, 0.4);
  cursor: pointer;
}
