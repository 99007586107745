.input-password-container {
  width: 100%;
  position: relative;
}

.input-password-svg-container {
  width: 20px;
  margin-left: 10px;
  position: absolute;
  top: calc(50% - 10px);
  right: 35px;
  fill: var(--text-medium);
}

.input-password-svg-container-show {
  fill: var(--primary);
}