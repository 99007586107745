.autocomplete-text-container {
  width: 100%;
  height: 36px;
  margin: 3px 0;
  padding: 0 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 1.2rem;
  background-color: var(--background-on-opacity);
  overflow: hidden;
}

.autocomplete-text-container:hover {
  color: var(--stay-white);
  background-color: var(--primary);
}
