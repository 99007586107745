.full-page-carousel-wrapper {
  width: 100vw;
  margin-top: 40px;
  position: relative;
}

.carousel-container {
  width: 300%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  list-style: none;
  transition: transform 0.3s cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.carousel-item-1 {
  background-image: linear-gradient(90deg, var(--primary), var(--primary-light));
}

.carousel-item-2 {
  background-image: linear-gradient(90deg, var(--primary-light), var(--secondary-light));
}

.carousel-item-3 {
  background-image: linear-gradient(90deg, var(--secondary-light), var(--secondary));
}

.carousel-item {
  width: 100vw;
  padding: 10px 0px 60px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
}

.carousel-video-container {
  width: 100%;
  height: 180px;
}

.carousel-text-highlight {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 900;
  line-height: 22px;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.7);
}

.carousel-item-image {
  width: 60%;
  max-width: 280px;
}

.carousel-logo {
  max-width: 50%;
  height: 38%;
  padding: 15px 0px;
}

.carousel-logo > svg {
  fill: var(--stay-white);
}

.carousel-item-content-container {
  width: calc(100vw - 85px);
  max-width: 600px;
  flex-shrink: 0;
  padding: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: var(--stay-white);
  border-radius: 4px;
  background-color: rgba(0,0,0,0.4);
  box-shadow: 0px 0px 5px rgba(0,0,0,0.6);
}

.carousel-header-title {
  margin-bottom: 5px;
  font-size: 1.3rem;
  text-align: center;
  color: var(--stay-white);
  text-shadow: 1px 1px 3px rgba(0,0,0,0.7);
}

.carousel-title {
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 1.7rem;
  text-align: center;
  color: var(--stay-white);
  text-shadow: 1px 1px 3px rgba(0,0,0,0.7);
}

.carousel-item-title {
  margin-top: 10px;
  font-size: 1.5rem;
  text-align: center;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.7);
}

.carousel-item-text {
  margin-top: 10px;
  font-size: 1.1rem;
  font-weight: 500; 
  line-height: 23px;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.6);
}

.carousel-list-container {
  max-width: calc(100% - 30px);
  padding: 0px 0px 30px 10px;
  list-style-type: square;
}

.carousel-list-item {
  margin-top: 10px;
  font-size: 1.1rem;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.6);
}

.carousel-button {
  width: 200px;
  min-width: 200px;
  min-height: 35px;
  margin-bottom: 10px;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 2px;
  background-color: var(--stay-white);
  box-shadow: 2px 2px 4px rgba(40, 40, 40, 0.4);
  cursor: pointer;
}

.carousel-prev,
.carousel-next {
  width: 25px;
  height: 35px;
  padding: 0px 3px;
  position: absolute;
  top: calc(50% - 30px);
  border-radius: 4px;
  fill: var(--stay-white);
  background-color: rgba(0,0,0,0.55);
  cursor: pointer;
  z-index: 10;
  transition: opacity 0.3s ease;
}

.carousel-prev {
  left: 10px;
  transform: rotate(180deg);
}

.carousel-next {
  right: 10px;
}

.carousel-prev:hover,
.carousel-next:hover {
  opacity: 1;
}

.carousel-pagination {
  list-style: none;
  position: absolute;
  bottom: 3%;
  left: 0;
  right: 0;
  width: 50%;
  padding: 0;
  margin: 0 auto;
  text-align: center;
  z-index: 10;
}

.carousel-bullet {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #000;
  cursor: pointer;
  margin: 0 7px;
  border-radius: 50%;
  opacity: 0.5;
  transition-property: transform, opacity, background-color;
  transition-duration: 0.3s;
}

.carousel-bullet-active {
  background-color: transparent;
  border: 2px solid rgba(0,0,0,0.5);
  cursor: none;
}

.carousel-bullet:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.carousel-bullet.active-bullet, 
.carousel-bullet.active-bullet:hover {
  opacity: 1;
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  background-color: #fff;
  cursor: default;
}

@media only screen and (min-width: 750px) {
  .carousel-item-content-container {
    padding: 25px 50px;
  }
  .carousel-video-container {
    height: 300px;
  }
}
