.promo-drawer-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.promo-drawer-image-container {
  width: 90%;
  max-width: 200px;
}