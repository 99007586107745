.welcome-wrapper {
  width: 100%;
  height: 125px;
  margin-top: -5px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.welcome-animation {
  width: 75%;
  max-width: 270px;
  margin-right: 90px;
  transform: rotate(-5deg);
}

.welcome-to-text {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--text-dark);
  transform: translateY(-20px);
  opacity: 0;
  animation: slideDown 400ms;
  animation-delay: 1700ms;
  animation-fill-mode: forwards;
}

.welcome-logo {
  height: 20px;
  fill: var(--text-dark);
  transform: translateX(30px);
  opacity: 0;
  animation: slideLeft 400ms;
  animation-delay: 2000ms;
  animation-fill-mode: forwards;
}

@keyframes slideDown {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(-8px);
    opacity: 1;
  }
}

@keyframes slideLeft {
  from {
    transform: translateX(30px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}