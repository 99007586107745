.other-ads-specs-container {
  min-height: 30px;
}

.other-ads-container {
  width: 150px;
  min-width: 150px;
  height: 150px;
  margin: 5px 10px 5px 0;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 0 2px rgba(10, 10, 10, 0.8);
  overflow: hidden;
}

.other-ads-name {
  font-size: 1.1rem;
}

.other-ads-image {
  width: 150px;
  min-width: 150px;
  height: 150px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--backgroundColor-secondary);
  fill: var(--backgroundColor);
}

.other-ads-title-conatiner {
  width: 150px;
  min-width: 150px;
  height: 150px;
  padding: 10px 5px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1;
}

.other-ads-title {
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.9);
  color: var(--stay-white);
}
