.profile-ads-pagination {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-ads-pagination-arrow-container {
  width: 20px;
  float: right;
  display: flex;
}

.profile-ads-pagination-arrow-container > svg {
  fill: var(--text-dark);
}

.profile-ads-pagination-number {
  margin: 0 8px;
  font-size: 1.2rem;
}

.profile-ads-pagination-number-active {
  color: var(--primary);
}
