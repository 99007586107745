.new-employee-info-form {
  width: 100%;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
}

.new-employee-info-form > * {
  margin-top: 10px;
} 