.app-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 20px;
  background-color: var(--backgroundColor);
  box-shadow: 2px 2px 4px rgba(40, 40, 40, 0.6);
  cursor: pointer;
}

.app-button-wrapper {
  box-shadow: 2px 2px 4px rgba(40, 40, 40, 0.8);
}

.app-button-svg-container {
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-button-svg-container > svg {
  fill: #f1f1f1;
  fill: var(--text-dark);
}

.app-button-text-Button {
  color: #f1f1f1;
  color: var(--text-dark);
  white-space: nowrap;
}
