.profile-verification-container {
  background-color: var(--secondary-light);
  border: 1px solid var(--secondary-dark);
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.6);
}

.profile-verification-text {
  width: 95%;
  margin: 0 0 15px 2.5%;
}

.profile-text-dark {
  color: var(--text-extra-dark) !important;
}
