.business-delivery-container {
  background-color: rgb(247, 247, 247);
  border: 1px solid var(--text-medium);
}

.business-delivery-logo {
  width: 50%;
  height: 60%;
}

.redemtion-button-code-text { 
  color: var(--text-dark);
}

.redemtion-button-code-text-active {
  color: var(--stay-white);
}
