.result-yelp-conatiner {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.result-yelp-text {
  margin-right: 3px;
}

.yelp-review-result-conatiner {
  width: 83px;
  height: 20px;
  margin-bottom: 2px;
  position: relative;
}